import React, { useEffect, useRef } from "react";
import { TextInput } from "../../../components";

const BasicInfo = ({ adminName }) => {
  const basicInfoFields = [
    {
      name: "candidateName",
      label: "Candidate Name",
      required: true,
      type: "text",
      placeholder: "Candidate Name",
    },
    {
      name: "email",
      label: "Email Id",
      required: true,
      type: "email",
      placeholder: "Candidate Email Id",
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      required: true,
      type: "tel",
      placeholder: "Candidate Phone Number",
      innerLabel: "+91",
      // validate :validatePhoneNumber
    },
    {
      name: "location",
      label: "Location",
      // required: true,
      required: false,
      type: "text",
      placeholder: "City, State, Country",
    },
    {
      name: "postalCode",
      label: "Zip/Postal Code",
      required: false,
      type: "text",
      placeholder: "Zip/Postal Codee",
    },
  ];

  // const [candidateId, setCandidateId] = useState();
  const candidateIdRef = useRef();
  useEffect(() => {
    candidateIdRef.current.value = `${adminName
      ?.slice(0, 3)
      .toUpperCase()}_Cand_${(Math.random() + 1).toString(36).substring(6)}`;
  }, [adminName]);

  return (
    <div>
      <div className="font-semibold">Basic information</div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-4 gap-x-6 mt-2">
        <div>
          <label htmlFor="candidateId" className="text-xs font-medium">
            Candidate Id
          </label>
          <TextInput
            type="text"
            inputName="candidateId"
            inputRef={candidateIdRef}
            isDisabled={true}
          />
        </div>
        {basicInfoFields.map((field) => (
          <div key={field.name}>
            <label htmlFor={field.name} className="text-xs font-medium">
              {field.label} {field.required && <span>*</span>}
            </label>
            <TextInput
              type={field.type}
              placeholderText={field.placeholder}
              inputName={field.name}
              isRequired={field.required}
              innerLabel={field.innerLabel && field.innerLabel}
              // isValidate={field.validate}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BasicInfo;
