import Select from "react-select";

const PositionTitle = ({ jobsData }) => {
  return (
    <div>
      <div className="text-sm"> Job Position*</div>
      <div className="grid md: grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <Select
          className="basic-single"
          classNamePrefix="select"
          name="jobPosition"
          isClearable={true}
          required={true}
          placeholder="Select Job Position"
          options={jobsData}
        />
      </div>
    </div>
  );
};

export default PositionTitle;
