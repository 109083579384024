import React from "react";
import { RxCross2 } from "react-icons/rx";
const Popup = ({ children, closePopup }) => {
  return (
    <div
      className="absolute top-0 left-0 w-full h-full py-2 z-[60] flex items-center justify-center bg-[#000]/20 backdrop-blur-[2px]"
      onClick={() => {
        closePopup();
      }}
    >
      <div
        className="relative card py-6 max-h-full px-4 overflow-y-scroll scrollable"
        onClick={(e) => e.stopPropagation()}
      >
        <RxCross2
          className="absolute top-6 right-8 w-8 h-8 bg-gray-300 text-gray-500 p-1 rounded-full cursor-pointer z-10"
          onClick={closePopup}
        />
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Popup;
