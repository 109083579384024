import { useEffect, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import useFetchCandidateProfile from "../../utils/hooks/useFetchCandidateProfile";

const ATSrows = ({
  index,
  columnList,
  candidate,
  setShowScheduleModule,
  setCandidateToSchedule,
  setCandidateToReschedule,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // fetch candidate profile details
  const [candidateDetails, setCandidateDetails] = useState(candidate);
  const candidateProfile = useFetchCandidateProfile(
    candidate?.candidateProfileRef,
    setLoading
  );
  useEffect(() => {
    if (candidateProfile) {
      setCandidateDetails({ ...candidate, ...candidateProfile });
    }
  }, [candidateProfile, candidate]);

  const [schedules, setSchedules] = useState([]);
  const [notAttemptedSchedules, setNotAttemptedSchedules] = useState([]);
  useEffect(() => {
    //fetch schedule data for the application id - candidate?.applicationId
    const fetchScheduleDetails = async () => {
      const q = query(
        collection(db, "assessmentTests"),
        where("applicationId", "==", candidate?.applicationId)
        // where("attempted", "==", false)
      );

      const querySnapshot = onSnapshot(q, (querySnapshot) => {
        const scheduleData = [];
        querySnapshot.forEach((doc) => {
          scheduleData.push({ id: doc.id, ...doc.data() });
        });
        const filteredScheduleData = scheduleData.filter((schedule) => {
          // filter out schedules that have been attempted
          return !schedule.attempted;
        });
        // console.log(scheduleData);
        setSchedules(scheduleData);
        setNotAttemptedSchedules(filteredScheduleData);
      });
      return querySnapshot;
    };
    fetchScheduleDetails();
  }, [candidate]);

  const openSchedulePopup = (type) => {
    // open date select popup
    setShowScheduleModule(type);
  };

  return (
    <>
      {loading ? (
        <div className="even:h-8 odd:h-6 rounded-full mx-3 even:bg-gray-100 animate-pulse"></div>
      ) : (
        <div
          className="flex w-full py-2 pl-5 text-xs font-medium even:bg-[#E9EAEB]/60 cursor-pointer"
          onClick={() => {
            navigate(`/candidateResults?id=${candidateDetails?.applicationId}`);
          }}
        >
          <div className="w-[50px] flex items-center">{index + 1}.</div>
          {columnList.map((column) => {
            const width = column.open ? column.width : "0";
            const opacity = column.open ? "1" : "0";
            return (
              <div
                key={column.name}
                className={`flex items-center gap-1 transition-all duration-200 overflow-hidden px-2
                ${column.name !== "email" && "capitalize"}
              `}
                style={{ width, opacity }}
              >
                {column.open &&
                  (column.name === "appliedAt" ? (
                    new Date(
                      candidateDetails?.appliedAt?.seconds * 1000
                    ).toLocaleDateString("en-in", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                  ) : column.name === "schedule" ? (
                    schedules?.length ? (
                      <Button
                        version={"outline-green"}
                        handleClick={(e) => {
                          e.stopPropagation();
                          openSchedulePopup("reschedule");
                          setCandidateToReschedule({
                            candidate: candidateDetails,
                            schedule: schedules[0],
                          });
                        }}
                      >
                        Reschedule
                      </Button>
                    ) : notAttemptedSchedules?.length ? (
                      "Rejected"
                    ) : (
                      <Button
                        version="outline-blue"
                        handleClick={(e) => {
                          e.stopPropagation();
                          openSchedulePopup("schedule");
                          setCandidateToSchedule({
                            candidate: candidateDetails,
                          });
                        }}
                      >
                        Schedule
                      </Button>
                    )
                  ) : candidateDetails?.[column.name] ? (
                    candidateDetails?.[column.name]
                  ) : (
                    <span className="pl-10">-</span>
                  ))}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ATSrows;
