import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { MdDelete } from "react-icons/md";
import { Button, TextInput } from "../../../components";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../config/firebase";
import { auth } from "../../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const Step2 = ({
  rounds,
  setRounds,
  assessmentNames,
  // setUpdateAssessmentTypes,
  // editJobData,
}) => {
  const [roundTypesOptions, setRoundTypesOptions] = useState([]);
  const [assessmentOptions, setAssessmentOptions] = useState([]);
  const [showAssessmentInput, setShowAssessmentInput] = useState([]);
  const [showSelectionCriteria, setShowSelectionCriteria] = useState([]);
  const creatableSelectRef = useRef(null);
  const [userAuthState] = useAuthState(auth);

  useEffect(() => {
    rounds.forEach((round, index) => {
      if (
        round?.roundType === "assessment" ||
        round?.roundType === "screening"
      ) {
        setShowAssessmentInput((prev) => [...prev, index]);
      }
    });
  }, [rounds]);

  useEffect(() => {
    console.log(showAssessmentInput);
  }, [showAssessmentInput]);

  const createOption = (label) => ({
    label: label.slice(0, 1).toUpperCase() + label.slice(1),
    value: label.toLowerCase().replace(/\W/g, "_"),
  });

  useEffect(() => {
    const roundType = ["Screening", "Assessment", "Interview"];
    const roundTypeOption = roundType.map((option) => createOption(option));
    setRoundTypesOptions(roundTypeOption);

    // const assessmentOption = assessmentNames.map((option) =>
    //   createOption(option)
    // );
    // setAssessmentOptions(assessmentOption);
  }, [assessmentNames]);

  // get assesment Name
  useEffect(() => {
    const fetchAssessmentData = async () => {
      let q = query(
        collection(db, "assessments"),
        where("companyName", "==", auth?.currentUser?.displayName)
      );
      onSnapshot(q, (querySnapshot) => {
        const assessmentData = [];
        querySnapshot.forEach((doc) => {
          assessmentData.push({ id: doc.id, ...doc.data() });
        });
        setAssessmentOptions(
          assessmentData.map((data) => {
            return {
              label: (
                <div className="flex items-center justify-between">
                  <span className="capitalize">{data.name}</span>
                  <span className="text-xs">
                    ({data.questions.length} questions)
                  </span>
                </div>
              ),
              value: data.name,
              id: data.id,
            };
          })
        );
      });
    };
    userAuthState && fetchAssessmentData();
  }, [userAuthState]);

  // options={assessments.map((assessment) => ({
  //   value: assessment.id,
  //   label: (
  //     <div style={{ display: 'flex', justifyContent: 'pace-between' }}>
  //       <span>{assessment.name}</span>
  //       <span style={{ fontSize: 12, color: '#666' }}>
  //         ({assessment.numQuestions} questions)
  //       </span>
  //     </div>
  //   ),
  // }))}

  // const createNewAssessment = async (assessmentName) => {
  // const sheetData = JSON.stringify([admin?.questionsSheetId, assessmentName]);
  // const response = await axios.post(
  // process.env.REACT_APP_CREATE_ASSESSMENT_TYPE,
  // sheetData
  // );
  // if (response.data.status === "success") {
  // setUpdateAssessmentTypes((prev) => prev + 1);
  // }
  // };

  // const handleCreate = async (inputValue, index) => {
  //   setIsLoading(true);
  //   const newOption = createOption(inputValue);
  //   setAssessmentOptions((prev) => [...prev, newOption]);
  //   await createNewAssessment(newOption.value);

  //   setIsLoading(false);
  // };console.log("rounds", rounds);
  // console.log(showSelectionCriteria)
  // console.log(editJobData)

  return (
    <div className="flex flex-col gap-5 w-[90%] mx-auto h-full">
      <h1 className="text-xl font-semibold">
        Select the rounds for this job profile
      </h1>
      <div className="flex flex-col gap-4 h-full overflow-y-auto scrollable">
        {rounds?.map((round, index) => (
          <div className="space-y-4 border-2 rounded-xl px-10 py-4" key={index}>
            <div className="flex items-center gap-4">
              <h1 className="text-lg font-semibold">Round {index + 1}</h1>
              {rounds.length > 1 && (
                <div
                  className="cursor-pointer bg-red-200 p-1 rounded-full"
                  onClick={() => {
                    const updatedRounds = rounds.filter((_, i) => i !== index);
                    setRounds(updatedRounds);
                    // setShowSelectionCriteria((prev)=> delete prev[index])
                  }}
                >
                  <MdDelete className="w-4 h-4 text-red-500" />
                </div>
              )}
            </div>
            <div className="flex gap-20">
              <div className="w-full flex flex-col gap-4">
                <div
                  className={`space-y-1 ${
                    rounds[index].roundType === "interview" && "w-[50%]"
                  } `}
                >
                  <h1 className="font-medium">Type of Round</h1>
                  <div className="w-full xl:w-3/4">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      name="RoundType"
                      options={roundTypesOptions}
                      defaultValue={{
                        label: round.roundType,
                        value: round.roundType,
                      }}
                      // value={{
                      //   label: rounds[index].roundType,
                      //   value: rounds[index].roundType,
                      // }}
                      placeholder="Select Type of Round"
                      onChange={(newValue) => {
                        const updatedRounds = rounds;
                        updatedRounds[index].roundType = newValue?.value;
                        setRounds(updatedRounds);
                        newValue?.value === "interview"
                          ? setShowSelectionCriteria((prev) => [...prev, true])
                          : setShowSelectionCriteria((prev) => [
                              ...prev,
                              false,
                            ]);
                        newValue?.value === "assessment" ||
                        newValue?.value === "screening"
                          ? setShowAssessmentInput((prev) =>
                              prev.includes(index) ? prev : [...prev, index]
                            )
                          : setShowAssessmentInput((prev) =>
                              prev.includes(index)
                                ? prev.filter((item) => item !== index)
                                : prev
                            );
                      }}
                    />
                  </div>
                </div>
                {showAssessmentInput.includes(index) && (
                  <div className="space-y-1">
                    <h1 className="font-medium">Assessmet Name</h1>
                    <div className="w-full xl:w-3/4">
                      <Select
                        ref={creatableSelectRef}
                        isClearable
                        defaultValue={{
                          label: round.assessment,
                          value: round.assessment,
                        }}
                        // isDisabled={isLoading}
                        // isLoading={isLoading}
                        // value={{
                        //   label: rounds[index].assessment,
                        //   value: rounds[index].assessment,
                        // }}
                        onChange={(newValue) => {
                          const updatedRounds = rounds;
                          updatedRounds[index].assessment = newValue?.value;
                          updatedRounds[index].id = newValue?.id;
                          setRounds(updatedRounds);
                        }}
                        // onCreateOption={(inputValue) =>
                        //   handleCreate(inputValue, index)
                        // }
                        options={assessmentOptions}
                        placeholder="Select/Create Assessment"
                      />
                    </div>
                  </div>
                )}
              </div>
              {rounds[index].roundType !== "interview" && (
                <div className="w-full flex flex-col gap-2">
                  <h1 className="font-medium">
                    Selection Criteria for Round {index + 1}{" "}
                  </h1>
                  <div className="w-full lg:w-1/2 xl:w-2/3">
                    <label htmlFor="criteria" className="text-xs">
                      Pass for next round if Score is greater then
                    </label>
                    <TextInput
                      type="number"
                      inputName="criteria"
                      placeholderText="eg. - 90"
                      innerLabel="%"
                      defaultValue={rounds[index].selectionCriteria}
                      // inputValue={rounds[index].selectionCriteria}
                      handleChange={(e) => {
                        const updatedRounds = rounds;
                        updatedRounds[index].selectionCriteria = Number(
                          e.target.value
                        );
                        setRounds(updatedRounds);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        <div>
          <Button
            text=""
            width="200px"
            version="outline-primary"
            handleClick={() => {
              const uniqueKey = new Date().getTime();
              setRounds([...rounds, { key: uniqueKey }]);
            }}
          >
            <span className="text-sm">+ Add Round</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Step2;
