import { getDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";

const useFetchCandidateProfile = (candidateProfileRef, setLoading) => {
  const [data, setData] = useState(null);

  const fetchProfileDetails = useCallback(async () => {
    try {
      if (candidateProfileRef) {
        const docSnapshot = await getDoc(candidateProfileRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setData(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading && setLoading(false);
    }
  }, [candidateProfileRef, setLoading]);

  useEffect(() => {
    fetchProfileDetails();
  }, [fetchProfileDetails, candidateProfileRef]);

  return data;
};

export default useFetchCandidateProfile;
