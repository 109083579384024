import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";

const JobDetails = ({ jobData, companyDetails, loading }) => {
  const [viewDescription, setViewDescription] = useState(false);

  return (
    <div className="hidden lg:flex card shadow-md w-1/3 justify-center overflow-hidden">
      {loading ? (
        <div className="w-full p-6">
          <div className="h-60 bg-gray-200 rounded-md flex items-center justify-center text-2xl text-gray-300 font-bold">
            Loading
          </div>
        </div>
      ) : jobData !== "" ? (
        <div className="w-full space-y-3">
          <div className="relative p-8">
            <div className="flex justify-between">
              <div className="font-semibold text-xl capitalize">
                {jobData?.positionTitle}
              </div>
              {/* <div className="text-red-500 text-sm font-medium">Report Job</div> */}
            </div>
            <Link
              to={companyDetails?.companyUrl}
              target="_blank"
              className="flex items-center gap-2"
            >
              <div className="w-14 h-14 bg-[#8A24F1]/20 rounded-full flex items-center justify-center border-2 border-gray-200 overflow-hidden">
                {companyDetails?.logoUrl ? (
                  <img src={companyDetails?.logoUrl} alt="companyLogo" />
                ) : (
                  <span className="text-[#8A24F1]/50 font-semibold text-3xl">
                    {jobData?.companyName.slice(0, 1)}
                  </span>
                )}
              </div>
              <div>
                <div className="text-[#8A24F1] font-medium text-xl">
                  {jobData?.companyName}
                </div>
                <div className="flex">
                  <div className="font-medium text-sm text-[#666]">
                    {jobData?.address?.city ? `${jobData?.address.city}, ` : ""}
                    {jobData?.address?.state
                      ? `${jobData?.address.state}, `
                      : ""}
                    {jobData?.address?.country}
                  </div>
                </div>
              </div>
            </Link>

            <div className="mt-3 font-medium text-sm space-y-1">
              <div className="flex space-x-2">
                <div className="text-[#666]">Job Type :</div>
                <div>{jobData?.jobType}</div>
              </div>
              <div className="flex space-x-2">
                <div className="text-[#666]">Work Experiance :</div>
                <div>{jobData?.workExperiance} Year</div>
              </div>
              {/* <div className="flex space-x-2">
                <div className="text-[#666]">Salary Range :</div>
                <div>
                  ₹ {jobData?.salary.min} - ₹ {jobData?.salary.max} / month
                </div>
              </div> */}
              {/* <div className="flex space-x-2">
                <div className="text-[#666]">Number of Positions :</div>
                <div>{jobData?.numberOfPosition}</div>
              </div> */}
              <div className="flex space-x-2">
                <div className="text-[#666]">Job Preference :</div>
                <div>
                  {jobData?.jobPrefence === "wfh"
                    ? "Work From Home"
                    : jobData?.jobPrefence === "hybrid"
                    ? "Hybrid"
                    : "Work From Office"}
                </div>
              </div>
              {jobData?.jobPrefence !== "wfh" && (
                <div className="flex space-x-2">
                  <div className="text-[#666]">Address :</div>
                  <div>
                    {jobData?.address?.city ? `${jobData?.address.city}, ` : ""}
                    {jobData?.address?.state
                      ? `${jobData?.address.state}, `
                      : ""}
                    {jobData?.address?.country}
                  </div>
                </div>
              )}
            </div>
            {viewDescription && (
              <div className="text-sm mt-5 text-[#666]">
                <div className=" font-semibold">Description :</div>
                <div className="max-h-[20rem] overflow-y-scroll scrollable pr-2">
                  {jobData.jobDescription}
                </div>
              </div>
            )}

            <div
              className="mt-2 cursor-pointer flex gap-1 items-center justify-center text-[#8A24F1]"
              onClick={() => setViewDescription(!viewDescription)}
            >
              <span className="text-sm font-medium">
                {viewDescription === false
                  ? "View job description"
                  : "Hide job Description"}
              </span>
              <FaAngleDown
                className={`w-4 h-4 transition duration-300
                            ${viewDescription && "transform rotate-180"}`}
              />
            </div>
            {jobData?.jobStatus !== "in-progress" && (
              <div className="absolute top-0 right-0 bg-gray-300/60 backdrop-blur-[1px] w-full h-full font-semibold z-50 flex items-center justify-center text-center">
                <div className="text-2xl font-semiboldss flex flex-col border-t-2 border-b-2 border-gray-500 px-10 -rotate-[20deg]">
                  This Position is{" "}
                  <span
                    className={
                      jobData?.jobStatus === "closed"
                        ? "text-red-700"
                        : jobData?.jobStatus === "on hold"
                        ? "text-yellow-600"
                        : "text-[#8A24F1]"
                    }
                  >
                    {(jobData?.jobStatus).charAt(0).toUpperCase() +
                      (jobData?.jobStatus).slice(1)}{" "}
                  </span>
                </div>
              </div>
            )}
          </div>
          {jobData?.jobStatus !== "in-progress" && (
            <div className="text-center mt-2 font-medium px-6">
              You can still apply
              {jobData?.jobStatus === "closed"
                ? " and join us for future job openings."
                : ", Your application would be automatically considerd as soon as positon resumes."}
            </div>
          )}
        </div>
      ) : (
        <div className="w-full pt-10 flex justify-center">
          <span className="text-2xl text-center text-[#888] font-semibold">
            No job details <br />
            to display
          </span>
        </div>
      )}
    </div>
  );
};

export default JobDetails;
