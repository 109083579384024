import React from "react";
import { TextInput } from "../../../components";

const ProfessionalDetails = () => {
  const professionalDetailsFields = [
    {
      name: "workExperiance",
      label: "Total Work Experiance",
      // required: true,
      type: "number",
      placeholder: "Total Work Experiance (in years)",
    },
    {
      name: "currentSalary",
      label: "Current Salary",
      required: false,
      type: "number",
      placeholder: "Current Salary (per month)",
    },
    {
      name: "expectedSalary",
      label: "Expected Salary",
      // required: true,
      type: "number",
      placeholder: "Expected Salary (per month)",
    },
    {
      name: "positionTitle",
      label: "Position Title",
      required: true,
      type: "text",
      placeholder: "Position Title",
    },
    {
      name: "currentJobTitle",
      label: "Current Job Title",
      required: false,
      type: "text",
      placeholder: "Current Job Title",
    },
    {
      name: "currentEmployer",
      label: "Current Employer",
      required: false,
      type: "text",
      placeholder: "Current Employer",
    },
    {
      name: "skills",
      label: "Skills (Comma Seperated )",
      required: false,
      type: "text",
      placeholder: "Skills (eg. - HTML, CSS, Javascript)",
    },
  ];
  return (
    <div>
      <div className="font-semibold">Professional Details</div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-4 gap-x-6 mt-2">
        {professionalDetailsFields.map((field) => (
          <div
            key={field.name}
            className={`${field.name === "skills" && "md:col-span-2"}`}
          >
            <label htmlFor={field.name} className="text-xs font-medium">
              {field.label} {field.required && <span>*</span>}
            </label>
            <TextInput
              type={field.type}
              placeholderText={field.placeholder}
              inputName={field.name}
              isRequired={field.required}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfessionalDetails;
