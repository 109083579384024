import React, { useState, useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { db } from "../../config/firebase";
import ApplyStep1 from "./Step1";
import ApplyStep2 from "./Step2";
import ApplyStep3 from "./Step3";
import JobDetails from "./JobDetails";
import { Button, Popup } from "../../components";

const ApplyLayout = ({ step }) => {
  const [jobData, setJobData] = useState("");
  const [loading, setLoading] = useState("");
  const location = useLocation();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const jobIdParam = params.get("jobId");
    const getDataFromFirestore = async () => {
      const docId = jobIdParam;
      if (!docId) return;
      const documentRef = doc(db, "jobsPosted", docId);
      setLoading(true);
      try {
        const docSnapshot = await getDoc(documentRef);
        if (docSnapshot.exists()) {
          setJobData({ ...docSnapshot.data() });
        } else {
          setJobData("");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getDataFromFirestore();
  }, [location]);

  const [companyDetails, setCompanyDetails] = useState(null);
  useEffect(() => {
    //fetch details from frirebase, collection - adminProfiles
    const fetchCompanyDetails = async () => {
      const q = query(
        collection(db, "adminProfile"),
        where("companyName", "==", jobData?.companyName)
      );
      try {
        setLoading(true);
        onSnapshot(q, (snapshot) => {
          const adminDeails = snapshot.docs.map((doc) => ({
            applicationId: doc.id,
            ...doc.data(),
          }));
          setCompanyDetails(adminDeails[0]);
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    jobData && fetchCompanyDetails();
  }, [jobData]);

  return (
    <div className="relative flex justify-center w-full h-screen p-20 gap-4">
      <div className="card shadow-md w-2/3 overflow-hidden">
        {step === 1 && (
          <ApplyStep1
            jobData={jobData}
            setShowSuccessPopup={setShowSuccessPopup}
          />
        )}
        {step === 2 && <ApplyStep2 jobData={jobData} />}
        {step === 3 && <ApplyStep3 />}
      </div>
      <JobDetails
        jobData={jobData}
        companyDetails={companyDetails}
        loading={loading}
      />
      {showSuccessPopup && (
        <Popup closePopup={() => setShowSuccessPopup(false)}>
          <div className="card py-5 px-10 text-center space-y-5">
            <h1 className="heading2">Assessment Details Sent!</h1>
            <p className="w-[22rem]">
              Please go through the Interview details sent to your email id.
            </p>
            <div className="w-full flex justify-center">
              <Button
                version="secondary"
                text="Ok"
                handleClick={() => {
                  // refresh page
                  window.location.reload(); //Todo : Needs improvment to handle further flow
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default ApplyLayout;
