import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextInput, UploadResume } from "../../components";
import { validateEmail } from "../../utils/validate";
import { validatePhoneNumber } from "../../utils/validate";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { db } from "../../config/firebase";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineEmail, MdOutlineLocalPhone } from "react-icons/md";
import emailTemplate from "../../utils/emailTemplates/jobApplied";
import scheduleEmailTemplate from "../../utils/emailTemplates/firstRoundSchedule";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { asyncHandler } from "../../utils/helperFunctions/asyncHandler";
import { sendEmailHandler } from "../../utils/helperFunctions/sendEmailHandler";

const ApplyStep1 = ({ jobData, setShowSuccessPopup }) => {
  const navigate = useNavigate();
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const [resumeUrl, setResumeUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dragging, setDragging] = useState(false);

  const inputFields = [
    {
      name: "name",
      ref: nameRef,
      type: "text",
      placeholder: "Full Name",
      innerLabel: <FaRegUser className="text-gray-400 w-5 h-5" />,
    },
    {
      name: "email",
      ref: emailRef,
      type: "email",
      placeholder: "Email Id",
      innerLabel: <MdOutlineEmail className="text-gray-400 w-5 h-5" />,
    },
    {
      name: "phone",
      ref: phoneRef,
      type: "tel",
      placeholder: "Phone Number",
      innerLabel: <MdOutlineLocalPhone className="text-gray-400 w-5 h-5" />,
    },
  ];

  const sendEmail = async (type, emailId, examCode, scheduleDate, expDate) => {
    let subject;
    let body;
    const dateFormat = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const timeFormat = { hour: "numeric", minute: "2-digit", hour12: true };
    if (type === "schedule") {
      ({ subject, body } = scheduleEmailTemplate({
        candidateName: nameRef.current?.value,
        candidateEmail: emailRef.current?.value,
        examCode: examCode,
        jobTitle: jobData?.positionTitle,
        companyName: jobData?.companyName,
        startDate: {
          date: scheduleDate?.toLocaleDateString("en-IN", dateFormat),
          time: scheduleDate?.toLocaleTimeString("en-IN", timeFormat),
        },
        expDate: {
          date: expDate?.toLocaleDateString("en-IN", dateFormat),
          time: expDate?.toLocaleTimeString("en-IN", timeFormat),
        },
      }));
    } else {
      ({ subject, body } = emailTemplate({
        jobTitle: jobData?.positionTitle,
        companyName: jobData?.companyName,
        candidateName: nameRef.current?.value,
      }));
    }
    try {
      await sendEmailHandler({
        emailId,
        subject,
        body,
        successMessage:
          type === "schedule"
            ? `Schedule email sent successfully to ${emailId}`
            : `Email sent successfully to ${emailId}`,
        autoClose: type === "schedule" ? false : 4000,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    setError((prev) => ({ ...prev, resume: "" }));
  }, [resumeUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // form values
    const name = nameRef.current.value?.trim();
    const email = emailRef.current.value?.trim();
    const phone = phoneRef.current.value?.trim();

    // form validation
    if (name === "") {
      setError((prev) => ({ ...prev, name: "Name is required" }));
    }
    if (!validateEmail(email)) {
      setError((prev) => ({ ...prev, email: "Enter a valid Email Id" }));
    }
    if (email === "") {
      setError((prev) => ({ ...prev, email: "Email is required" }));
    }
    if (!validatePhoneNumber(phone))
      setError((prev) => ({
        ...prev,
        Phone: "Enter a valid 10 digit phone no.",
      }));

    if (resumeUrl === "")
      setError((prev) => ({ ...prev, resume: "Resume is required" }));
    if (
      name === "" ||
      !validateEmail(email) ||
      !validatePhoneNumber(phone) ||
      resumeUrl === ""
    )
      return;

    // if (authExists) {
    //   // apply function for register candidate
    //   // - add apply data to candidateApplications with candidateProfileRef of registered user
    //   // - add schedule data
    //   // - schedule email
    // } else {
    //   // apply function for unregistered candidate
    // }

    setLoading(true);
    const candidateDetails = {
      name,
      email,
      phoneNumber: "+91" + phone,
      resumeUrl,
    };

    try {
      // 1. Save candidate profile to db (collection : candidateProfile)
      const profileRef = await asyncHandler({
        func: () =>
          addDoc(collection(db, "candidateProfile"), candidateDetails),
        errorMessage: "Failed to save candidate profile",
      });

      const applicationDetails = {
        appliedAt: Timestamp.fromDate(new Date()),
        appliedAtCompany: jobData?.companyName,
        appliedJobId: jobData?.jobOpeningId,
        appliedPosition: jobData?.positionTitle,
        candidateProfileRef: profileRef,
        source: "by candidate",
        resumeUrl,
      };
      //2. save application data to db (ccollection : candidateApplications)
      const applicationRef = await asyncHandler({
        func: () =>
          addDoc(collection(db, "candidateApplications"), applicationDetails),
        errorMessage: "Failed to save application details",
      });

      //3.  email to candidate for successful application and option to register
      await sendEmail("successApply", email);

      //4. save schedule data to db ( collection : assessmentTests)
      const examCode = (Math.random() + 1).toString(36).substring(6);
      const scheduleDate = new Date();
      const expDate = new Date(scheduleDate.getTime() + 1000 * 60 * 60 * 48);
      const scheduleDetails = {
        applicationId: applicationRef?.id,
        attempted: false,
        candidateEmail: email,
        candidateId: profileRef?.id,
        companyName: jobData?.companyName,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
        examCode,
        scheduleDate: Timestamp.fromDate(scheduleDate),
        expDate: Timestamp.fromDate(expDate),
        jobId: jobData?.jobOpeningId,
      };
      await asyncHandler({
        func: () => addDoc(collection(db, "assessmentTests"), scheduleDetails),
        errorMessage: "Failed to save schedule details",
      });

      //5. email to candidate for schedule details
      await sendEmail("schedule", email, examCode, scheduleDate, expDate);

      // 6. show success popup
      setShowSuccessPopup(true);

      //6. storing details to local storage
      // await asyncHandler({
      //   func: () => {
      //     localStorage.setItem(
      //       "talentigo-CandidateApplyData",
      //       JSON.stringify({
      //         name,
      //         email,
      //         phone,
      //         jobData: jobData?.positionTitle,
      //       })
      //     );
      //   },
      //   errorMessage: "Failed to store candidate details",
      // });

      // navigate(`/candidateApply/step2`);

      //7. check either to navigate for login page or regiter page
      // await asyncHandler({
      //   func: () => {
      //     fetchSignInMethodsForEmail(auth, email).then((result) => {
      //       if (result.length > 0) {
      //         navigate("/candidateLogin");
      //       } else {
      //         navigate("/candidateRegistration");
      //       }
      //     });
      //   },
      //   errorMessage: "Failed to check user existence",
      // });
    } catch (error) {
      console.log(error);
      setError((prev) => ({
        ...prev,
        other: error.message,
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`relative w-full h-full p-10 px-16 space-y-10`}
      onDragOver={() => setDragging(true)}
      onDrop={() => setDragging(false)}
    >
      <h1 className="text-2xl font-semibold">
        Apply {jobData?.companyName ? `to ${jobData?.companyName}` : ""}
      </h1>
      <form onSubmit={handleSubmit} className="w-full xl:w-2/3">
        {inputFields.map((field) => {
          return (
            <div key={field.name}>
              <label htmlFor={field.name} className="capitalize text-sm">
                {field.name}*
              </label>
              <TextInput
                innerLabel={field.innerLabel}
                type={field.type}
                idName={field.name}
                inputRef={field.ref}
                placeholderText={field.placeholder}
                // isRequired={true}
                isDisabled={loading}
              />
              <div className="text-xs mt-1 text-red-500 h-4">
                {field.name === "name"
                  ? error?.name || ""
                  : field.name === "email"
                  ? error?.email || ""
                  : field.name === "phone"
                  ? error?.phone || ""
                  : ""}
              </div>
            </div>
          );
        })}
        <div>
          <UploadResume
            setResumeUrl={setResumeUrl}
            dragging={dragging}
            setDragging={setDragging}
          />
          <div className="text-xs mt-1 text-red-500 ">
            {error?.resume || ""}
          </div>
        </div>
        <div>
          <div className="text-sm text-red-500 w-full h-4 my-2">
            {error?.other || ""}
          </div>
          <Button
            type="submit"
            loading={loading}
            text={loading ? "Please Wait" : "Next"}
            width="10rem"
          />
        </div>
      </form>
    </div>
  );
};

export default ApplyStep1;
