import React, { useEffect, useState } from "react";
import devtools from "devtools-detect";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../config/firebase";

import { Topbar2, ConfirmationModule, WarningModule } from "../../components";
import ExamTopPanel from "./ExamTopPanel";
import ExamQuestionPanel from "./ExamQuestionPanel";
import ExamOptionsPanel from "./ExamOptionsPanel";
import SubmitModule from "./SubmitModule";
import {
  handleFullScreen,
  sendEmail,
  fetchCompanyDetails,
  fetchQuestion,
} from "./examFunctions";
import Proctoring from "./Proctoring";
import ExamLoadingModule from "./ExamLoadingModule";
import { violationTypes } from "./violationTypes";
// import NoScreenSharing from '../../components/popup/NoScreenSharing';

const ExamPage = () => {
  const [loading, setLoading] = useState(true);
  const [startTime, setStartTime] = useState();
  const [timer, setTimer] = useState(60);
  const [timerPause, setTimerPause] = useState(false);

  const [confirmationMessage, setConfirmationMessage] = useState([]);
  const [confirmationButton, setConfirmationButton] = useState([]);

  const [warningCount, setWarningCount] = useState(0);
  const [warningType, setWarningType] = useState("");
  const [warningMessage, setWarningMessage] = useState([]);
  const [violations, setViolations] = useState([]);
  const [maxViolations] = useState(4);

  const [showWarning, setShowWarning] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSubmitModule, setShowSubmitModule] = useState(false);

  const [scheduleDetails, setScheduleDetails] = useState();
  const [assessmentDetails, setAssessmentDetails] = useState();
  const [candidateDetails, setCandidateDetails] = useState();
  const [applicationDetails, setApplicationDetails] = useState();
  const [jobDetails, setJobDetails] = useState();
  const [companyDetails, setCompanyDetails] = useState();

  const [assessmentResults, setAssessmentResults] = useState([]);

  // fetch schedule, assessment and candidate Details from local storage
  useEffect(() => {
    const schedule = JSON.parse(localStorage.getItem("scheduleDetails"));
    const assessment = JSON.parse(localStorage.getItem("assessmentDetails"));
    const candidate = JSON.parse(localStorage.getItem("candidateDetails"));
    const job = JSON.parse(localStorage.getItem("jobDetails"));
    const application = JSON.parse(localStorage.getItem("applicationDetails"));
    if (schedule) {
      setScheduleDetails(schedule);
      const details = fetchCompanyDetails(schedule?.companyName);
      setCompanyDetails(details);
    }
    if (assessment) setAssessmentDetails(assessment);
    if (candidate) setCandidateDetails(candidate);
    if (job) setJobDetails(job);
    if (application) setApplicationDetails(application);
  }, []);

  // fetch questions
  const [questionLoading, setQuestionLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [qusNum, setQusNum] = useState(0);

  useEffect(() => {
    const questionIds = assessmentDetails?.questions;
    if (questionIds?.length) {
      setQuestionLoading(true);

      try {
        questionIds.forEach(async (quesId) => {
          const qusData = await fetchQuestion(quesId);
          setQuestions((prev) => [...prev, qusData]);
        });
      } catch (error) {
        console.log(error);
      } finally {
        setQuestionLoading(false);
      }
    }
  }, [assessmentDetails]);

  const [loadingPercent, setLoadingPercent] = useState(0);
  const [proctorLoading, setProctorLoading] = useState(true);

  useEffect(() => {
    !questionLoading && setLoading(false);
    // !questionLoading && !proctorLoading && setLoading(false);
    // loadingPercent >= 100 && setLoading(false);
    !proctorLoading && setCameraDetection(true);
  }, [questionLoading, proctorLoading]);

  useEffect(() => {
    setStartTime(new Date());
    setTimer(questions[0]?.time);
  }, [questions]);

  const showViolations = (type) => {
    setTimerPause(true);
    setCameraDetection(false);
    setListning(false);

    setViolations([...violations, violationTypes[type]?.violation]);
    setWarningType(type);
    type !== "voice" && setWarningCount(warningCount + 1);
    setWarningMessage(violationTypes[type].message);
    setShowWarning(true);
  };

  // hide warning Module
  const hideModule = () => {
    if (warningCount === maxViolations - 1) {
      setWarningMessage([
        `This is your last warning`,
        "If you violate the assessment rules again, Your assessment will be terminated.",
      ]);
      setWarningType("lastWarning");
    } else {
      closeWarning();
    }
  };
  const closeWarning = () => {
    handleFullScreen();
    setShowWarning(false);
    setTimerPause(false);
    // setcellPhoneDetection(0);
    // setListning(true);
    // setCameraDetection(true);
  };

  // Terminate on Refresh
  useEffect(() => {
    const isRefresh = localStorage.getItem("refresh");
    if (isRefresh) {
      showViolations("refresh");
    }
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    localStorage.setItem("refresh", true);
  };
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
  }, []);

  // Tab switching
  const [tabSwitch, setTabSwitch] = useState(false);
  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState !== "visible") {
        setTabSwitch(true);
      } else {
        if (tabSwitch) {
          showViolations("tabSwitch");
        }
        setTabSwitch(false);
      }
    });
  });

  // full screen detection
  const fullScreenCheck = () => {
    if (!document.fullscreenElement) {
      showViolations("fullScreen");
    }
  };
  useEffect(() => {
    // fullScreen check for Chrome,Edge,Safari
    document.addEventListener("webkitfullscreenchange", () =>
      fullScreenCheck()
    );
    // fullscreem check for mozilla Firefox
    document.addEventListener("mozfullscreenchange", () => fullScreenCheck());
  });

  //dev tools check
  useEffect(() => {
    window.addEventListener("devtoolschange", (e) => {
      if (e.detail.isOpen) {
        showViolations("devtools");
      } else {
        setWarningType("devtools-close");
      }
    });
  });

  useEffect(() => {
    // Prevent Ctrl+S, Ctrl+C & Ctrl+V
    document.onkeydown = (e) => {
      e = e || window.event;
      if (e.ctrlKey) {
        let c = e.code;
        // console.log(e)
        if (
          ["ShiftLeft", "ShiftRight", "KeyS", "KeyC", "KeyX", "KeyV"].includes(
            c
          )
        ) {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    };

    // Prevent Screenshot
    document.onkeyup = (e) => {
      if (e.code === "PrintScreen") {
        e.preventDefault();
        e.stopPropagation();
        showViolations("screenShot");
      }
    };
  });

  // handle multiplt person detiction
  const [faceCount, setFaceCount] = useState();
  const [cameraDetection, setCameraDetection] = useState();
  useEffect(() => {
    if (!loading && faceCount === 0 && cameraDetection) {
      showViolations("noFace");
    } else if (faceCount > 1 && cameraDetection) {
      showViolations("multiplePerson");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faceCount]);

  // check camera enabled
  const [isCameraEnable, setisCameraEnable] = useState(true);
  useEffect(() => {
    if (!loading && !isCameraEnable && cameraDetection) {
      showViolations("cameraDisabled");
    } else {
      setWarningType("cameraEnabled");
    }
  }, [isCameraEnable]);

  //   //handle cellPhone detection
  const [cellPhoneDetection, setcellPhoneDetection] = useState(0);
  useEffect(() => {
    if (!loading && cellPhoneDetection > 0) {
      showViolations("cellPhoneDetected");
    }
  }, [cellPhoneDetection]);

  //   //handle voice detection
  const [voice, setVoice] = useState();
  const [listning, setListning] = useState(true);
  useEffect(() => {
    // console.log(voice)
    if (!loading && voice?.length > 10) {
      showViolations("voice");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voice]);

  // handle Terminate
  const handleTerminate = () => {
    setViolations([...(violations + "terminate")]);
    setShowWarning(false);
    handleSubmit();
  };

  const [code, setCode] = useState("");
  //next question button
  const handleNext = (codeOutput, codeEvaluate) => {
    const form = document.getElementById("examForm");
    const question = questions[qusNum];
    let userAnswer =
      question?.type === "code" ? code : form[`q${qusNum + 1}`]?.value;

    let evaluation = "";
    if (question?.type === "mcq") {
      evaluation =
        userAnswer === ""
          ? "unattempted"
          : question.options[userAnswer] === question.correctOption
          ? "correct"
          : "incorrect";
    } else if (question?.type === "subjective") {
      evaluation = userAnswer === "" ? "unattempted" : "pending";
    } else if (question?.type === "code") {
      evaluation = userAnswer === "" ? "unattempted" : codeEvaluate;
    }

    const quesResult = {
      question: question,
      userAnswer:
        question?.type === "mcq" ? question.options[userAnswer] : userAnswer,
      violations: violations,
      evaluation: evaluation,
      codeTestCases: question?.Type === "code" ? codeOutput : "",
    };
    setAssessmentResults([...assessmentResults, quesResult]);
    if (qusNum < questions.length - 1) {
      setQusNum(qusNum + 1);
      form.reset();
      setTimer(questions[qusNum + 1]?.time);
    } else {
      setTimerPause(true);
    }
  };

  // skip confirmation module
  const alertSkip = () => {
    setTimerPause(true);
    setConfirmationMessage([
      "Do you want to skip ?",
      "You will not be able to come back to this Question later.",
    ]);
    setConfirmationButton(["Skip Question", "Back to Question"]);
    setShowConfirmation(true);
  };

  //confirm skip
  const confirmSkip = () => {
    if (qusNum + 1 >= questions?.length) {
      document.getElementById("last").classList.add("block");
      document.getElementById("last").classList.remove("hidden");
      document.getElementById("next").style.display = "none";
    }
    handleNext();
    cancelAlert();
  };

  //cancel module
  const cancelAlert = () => {
    setShowConfirmation(false);
    setTimerPause(false);
  };

  //submit confirmation module
  const alertSubmit = () => {
    setTimerPause(true);
    if (qusNum !== questions.length - 1) {
      setConfirmationMessage([
        `${questions.length - qusNum - 1} Questions still remaining !`,
        "Are you sure you want to skip the pending questions and submit the test ?",
      ]);
      setConfirmationButton(["Submit Anyways", "Back to Test"]);
    } else {
      setConfirmationMessage([
        "Are you sure ?",
        "You want to submit the test ?",
      ]);
      setConfirmationButton(["Submit", "Back to Test"]);
    }
    setShowConfirmation(true);
  };

  //confirm submit
  const confirmSubmit = async () => {
    setTimerPause(true);
    await handleSubmit();
    cancelAlert();
  };

  //submit exam
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async () => {
    // exam submit function
    setSubmitLoading(true);
    const timeTaken = new Date() - new Date(startTime);
    let attempted = 0;
    let correctAnswers = 0;
    let pendingEvaluation = 0;
    let round = applicationDetails?.round ? applicationDetails?.round : 0;
    const currentRound = applicationDetails?.round
      ? applicationDetails?.round
      : 0;
    let totalQuestions = questions?.length;

    // calcluate the above properties
    assessmentResults?.map((result) => {
      if (result?.evaluation === "correct") correctAnswers++;
      if (result?.evaluation === "pending") pendingEvaluation++;
      if (result?.evaluation !== "unattempted") attempted++;
      return "";
    });
    let result;
    let score = Math?.round(
      (correctAnswers / (questions?.length - pendingEvaluation)) * 100
    );

    //Check if it a last assessment round
    let lastRound;
    const totalRounds = jobDetails?.rounds?.filter(
      (round) => round?.roundType !== "interview"
    )?.length;
    if (round + 1 === totalRounds) {
      lastRound = true;
    } else {
      lastRound = false;
    }

    // find selection Criteria and compare with score
    const selectionCriteria = jobDetails?.rounds[round]?.selectionCriteria;

    if (pendingEvaluation > 0) {
      result = "pending";
      await sendEmail({
        type: "pending",
        candidateDetails,
        positionTitle: jobDetails?.positionTitle,
        companyName: scheduleDetails?.companyName,
      });
    } else {
      if (score >= selectionCriteria) {
        round++;
        result = "pass";
        // add schedule data to db
        if (!lastRound) {
          const examCode = (Math.random() + 1).toString(36).substring(6);
          const scheduleDate = new Date();
          const expDate = new Date(
            scheduleDate.getTime() + 1000 * 60 * 60 * 48
          );
          const scheduleData = {
            candidateId: scheduleDetails?.candidateId,
            applicationId: scheduleDetails?.applicationId,
            jobId: scheduleDetails?.jobId,
            assessmentId: jobDetails?.rounds[round]?.id,
            candidateEmail: scheduleDetails?.candidateEmail,
            companyName: scheduleDetails?.companyName,
            examCode,
            scheduleDate: Timestamp.fromDate(scheduleDate),
            expDate: Timestamp.fromDate(expDate),
            attempted: false,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
          };

          await addDoc(collection(db, "assessmentTests"), scheduleData);

          // send email to candidate - for clearing this round
          await sendEmail({
            type: "pass",
            candidateDetails,
            companyName: scheduleDetails?.companyName,
            positionTitle: jobDetails?.positionTitle,
            examCode: examCode,
            scheduleDate: scheduleDate,
            expDate: expDate,
          });
        } else {
          // email to candidate and admin for clearing last round
          await sendEmail({
            type: "passLast",
            candidateDetails,
            companyName: scheduleDetails?.companyName,
            positionTitle: jobDetails?.positionTitle,
          });
          // await sendEmail({
          //   type: "passLastAdmin",
          //   candidateDetails,
          //   companyName: scheduleDetails?.companyName,
          //   positionTitle: jobDetails?.positionTitle,
          //   companyEmail: companyDetails?.companyEmail,
          // });
        }
      } else {
        result = "fail";
        await sendEmail({
          type: "fail",
          candidateDetails,
          companyName: scheduleDetails?.companyName,
        });
      }
    }

    // add the results to the candidate Application
    const candidateApplicationRef = doc(
      db,
      "candidateApplications",
      scheduleDetails?.applicationId
    );
    const resultData = {
      round: currentRound,
      examDateTime: Timestamp.fromDate(startTime),
      timeTaken,
      totalQuestions,
      attemptedQuestions: attempted,
      correctAnswers,
      pendingEvaluation,
      score,
      result,
      resultDetails: assessmentResults,
    };

    let roundAttempted = jobDetails?.rounds[currentRound];

    try {
      await updateDoc(candidateApplicationRef, {
        round: round,
        currentRound: roundAttempted,
        assessmentsResults: [
          ...(applicationDetails?.assessmentsResults
            ? applicationDetails?.assessmentsResults
            : []),
          resultData,
        ],
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitLoading(false);
    }
    //-------------------------------------------------------
    // const nid = `nid_${Date.now()}`;
    // const notificationTime = new Date().toLocaleString("en-in", {
    //   hour: "numeric",
    //   minute: "numeric",
    // });
    // const notificationBody = `${candidateDetails?.name} had submitted the Assessment.`;
    // let notificationData = `${dataSheetId}|${nid}|${hrEmail}|${uid}|${new Date().toDateString()}|${notificationTime}|${notificationBody}`;
    // let addNotificationApi = process.env.REACT_APP_ADD_NOTIFICATION;
    // setTimeout(() => {
    //   axios.post(postResultsApi, sheetData);
    //   axios.post(addNotificationApi, notificationData);
    //   axios.post(mailApi, emailData);
    // }, 100);

    // --------------------------------------------------
    localStorage.removeItem("scheduleDetails");
    localStorage.removeItem("candidateDetails");
    localStorage.removeItem("assessmentDetails");
    localStorage.removeItem("applicationDetails");

    localStorage.removeItem("refresh");
    setShowSubmitModule(true);
  };

  // Time's Up
  const handleTimeUp = () => {
    if (qusNum !== questions.length - 1) {
      handleNext();
    } else {
      confirmSubmit();
    }
  };

  return (
    <div
      className="h-[65vh] select-none"
      onContextMenu={(e) => e.preventDefault()}
    >
      <div className="fixed w-full z-10">
        <Topbar2
          name={candidateDetails?.name}
          email={candidateDetails?.email}
        />
      </div>

      {/* <div className="absolute top-4 left-[50%] translate-x-[-50%] z-50">
        <Proctoring
          setFaceCount={setFaceCount}
          setVoice={setVoice}
          listning={listning}
          setCellPhoneDetection={setcellPhoneDetection}
          // webcamRef={webcamRef}
          setProctorLoading={setProctorLoading}
          setisCameraEnable={setisCameraEnable}
          setLoadingPercent={setLoadingPercent}
        />
      </div> */}

      {loading && (
        <div className="absolute w-full h-[100vh] top-0 left-0 z-30 flex justify-center items-center bg-black/[0.15] backdrop-blur-[4px]">
          <ExamLoadingModule loadingPercent={loadingPercent} />
        </div>
      )}

      <div>
        <ExamTopPanel
          exam={assessmentDetails?.name}
          qusNum={qusNum}
          duration={timer}
          timeUp={handleTimeUp}
          timerPause={timerPause}
          alertSubmit={alertSubmit}
          loading={loading}
          submitLoading={submitLoading}
        />
      </div>

      <div className="w-[95%] h-full mx-auto py-5 flex card">
        <ExamQuestionPanel
          questions={questions}
          qusNum={qusNum}
          loading={loading}
        />
        <ExamOptionsPanel
          questions={questions}
          handleNext={handleNext}
          qusNum={qusNum}
          alertSkip={alertSkip}
          loading={loading}
          code={code}
          setCode={setCode}
        />
      </div>

      {showConfirmation && (
        <div className="moduleCover">
          <ConfirmationModule
            confirmationMessage={confirmationMessage}
            confirmationButton={confirmationButton}
            cancelAlert={cancelAlert}
            confirmSkip={confirmSkip}
            confirmSubmit={confirmSubmit}
            next={document.getElementById("next")}
            submitLoading={submitLoading}
          />
        </div>
      )}
      {showWarning && (
        <div className="moduleCover">
          <WarningModule
            type={warningType}
            warningCount={warningCount}
            warningMessage={warningMessage}
            handleTerminate={handleTerminate}
            hideModule={hideModule}
            closeWarning={closeWarning}
            maxViolations={maxViolations}
          />
        </div>
      )}
      {showSubmitModule && (
        <div className="moduleCover">
          <SubmitModule
            candidateDetails={candidateDetails}
            applicationDetails={applicationDetails}
          />
        </div>
      )}
    </div>
  );
};

export default ExamPage;
