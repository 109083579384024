import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { query, onSnapshot, collection, where } from "firebase/firestore";
import { db } from "../../config/firebase";

import { Sidebar, Topbar3 } from "../../components";
import {
  Dashboard,
  CandidateProfile,
  Notifications,
  AdminProfile,
  Settings,
  ATSMain,
  NewCandidate,
  Jobs,
  NewJob,
  QuestionLibrary,
  LibQuestionUpload,
  Assessments,
} from "..";
import { ToastContainer } from "react-toastify";
import NewAssessment from "../assessments/newAssessment/NewAssessment";

const Admin = ({ page, tab }) => {
  useEffect(() => {
    document.title = `Talentigo | ${
      page.slice(0, 1).toUpperCase() + page.slice(1)
    }`;
  }, [page]);

  // console.log(auth)
  // states
  const [admin, setAdmin] = useState(null);
  const [sideBarOpen, setSideBarOpen] = useState("none");
  const [screen, setScreen] = useState();

  const [isprofileMenuOpen, setIsprofileMenuOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);

  const navigate = useNavigate();

  const closePopupMenu = () => {
    setIsprofileMenuOpen(false);
    setIsNotificationOpen(false);
    setSearchOpen(false);
  };

  //find screen size
  useEffect(() => {
    window.innerWidth > 767 && setScreen("large");
    window.innerWidth > 767 && setSideBarOpen("small");
    window.addEventListener("resize", () => {
      window.innerWidth > 767
        ? setSideBarOpen("small")
        : setSideBarOpen("none");
      window.innerWidth > 767 ? setScreen("large") : setScreen("small");
    });
  }, []);

  //fetching data
  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("adminLogin"));
    if (loginData.uid) {
      const q = query(
        collection(db, "adminProfile"),
        where("uid", "==", loginData.uid)
      );
      const snapShot = onSnapshot(q, (querySnapshot) => {
        // const adminData = [];
        querySnapshot.forEach((doc) => {
          setAdmin({ ...doc.data(), id: querySnapshot.docs[0].id });
        });
      });
    }
  }, []);
  // console.log(admin)
  useEffect(() => {
    let loginWindow = 48 * 60 * 60 * 1000;
    if (Date.now() - admin?.loginTime > loginWindow) {
      localStorage.removeItem("adminLogin");
      window.location.href = "/admin";
    } else {
      const updateTime = () => {
        const loginData = JSON.parse(localStorage.getItem("adminLogin"));
        loginData.loginTime = Date.now();
        localStorage.setItem("adminLogin", JSON.stringify(loginData));
      };
      updateTime();
    }
  }, [admin, page]);

  const [notifications, setNotifications] = useState();
  const [notificationsLoading, setNotificationsLoading] = useState(false);

  // fetch notification details

  useEffect(() => {
    // const loginData = JSON.parse(localStorage.getItem("adminLogin"));
    if (admin?.uid) {
      setNotificationsLoading(true);
      const q = query(
        collection(db, "notifications"),
        where("notificaitonFor.uid", "==", admin.uid)
      );
      // const documentRef = collection(db,"notifications")
      const snapShot = onSnapshot(q, (querySnapshot) => {
        const notificationsData = [];
        querySnapshot.forEach((doc) => {
          notificationsData.push({
            ...doc.data(),
            id: querySnapshot.docs[0].id,
          });
        });
        setNotifications(notificationsData);
        setNotificationsLoading(false);
      });
    }
  }, [admin]);

  // console.log(notifications)

  // const fetchapi = process.env.REACT_APP_FETCH_API;

  // admin data use property

  // Toolbar3 = admin?.dataSheetId ,admin?.logoUrl
  // ATSMains = admin?.name
  // NewCandidates = admin?.name
  // Jobs  = admin?.name
  // NewJob(newJobLayout) = admin?.name,  step2 =admin?.name
  // LibQuestionUpload = admin , UploadSingleQuestion  = admin -SingleQuestionUpload  ,UploadCSV =admin?.name
  // Assessments =admin?.name
  // NewAssessmen = admin , CustomAssessment = admin?.name , LibraryAssessment = admin?.name
  // Settings = admin.email
  // AdminProfile =admin?.email , admin?.logoUrl , admin?.name ,admin?.companyUrl

  //fetching schedules
  // const [schedules, setSchedules] = useState([]);
  const [allSchedules, setAllSchedules] = useState([]);
  // const [totalShedules, setTotalSchedules] = useState();
  // const [scheduleFilter, setScheduleFilter] = useState("all");
  // const [scheduleSortBy, setScheduleSortBy] = useState("date");
  // const [scheduleSortOrder, setScheduleSortOrder] = useState("assending");
  // const [updateSchedules, setUpdateSchedules] = useState(0);
  const [schedulesLoading, setSchedulesLoading] = useState(true);
  // useEffect(() => {
  //   if (admin?.dataSheetId) {
  //     const fetchSchedules = async () => {
  //       setSchedulesLoading(true);
  //       const scheduleData = JSON.stringify([
  //         admin?.dataSheetId,
  //         1,
  //         50,
  //         scheduleFilter,
  //         scheduleSortBy,
  //         scheduleSortOrder,
  //       ]);
  //       const scheduleResponse = await axios.post(
  //         process.env.REACT_APP_FETCH_SCHEDULES_API,
  //         scheduleData
  //       );
  //       setSchedules(scheduleResponse.data.records);
  //       setTotalSchedules(scheduleResponse.data.totalRecords);
  //       setAllSchedules(scheduleResponse.data.allData);
  //       setTimeout(() => setSchedulesLoading(false), 100);
  //     };
  //     fetchSchedules();
  //   }
  // }, [
  //   updateSchedules,
  //   scheduleFilter,
  //   scheduleSortBy,
  //   scheduleSortOrder,
  //   admin?.dataSheetId,
  // ]);

  // fetching results
  // const [results, setResults] = useState([]);
  const [allResults, setAllResults] = useState([]);
  // const [totalResults, setTotalResults] = useState();
  // const [resultFilter, setResultFilter] = useState("all");
  // const [resultSortBy, setResultSortBy] = useState("date");
  // const [resultSortOrder, setResultSortOrder] = useState("assending");
  // const [updateResults, setUpdateResults] = useState(0);
  const [resultsLoading, setResultsLoading] = useState(true);
  // useEffect(() => {
  //   if (admin?.dataSheetId) {
  //     const fetchResults = async () => {
  //       setResultsLoading(true);
  //       const resultData = JSON.stringify([
  //         admin?.dataSheetId,
  //         "results",
  //         1,
  //         50,
  //         resultFilter,
  //         resultSortBy,
  //         resultSortOrder,
  //       ]);
  //       const resultResponse = await axios.post(
  //         process.env.REACT_APP_FETCH_RESULTS_API,
  //         resultData
  //       );
  //       setResults(resultResponse.data.records);
  //       setAllResults(resultResponse.data.allData);
  //       setTotalResults(resultResponse.data.totalRecords);
  //       setTimeout(() => setResultsLoading(false), 100);
  //     };
  //     fetchResults();
  //   }
  // }, [
  //   updateResults,
  //   resultFilter,
  //   resultSortBy,
  //   resultSortOrder,
  //   admin?.dataSheetId,
  // ]);

  // fetching coding results
  // const [codingResults, setCodingResults] = useState([]);
  // const [allCodingResults, setAllCodingResults] = useState([]);
  // const [totalCodingResults, setTotalCodingResults] = useState();
  // const [codingResultFilter, setCodingResultFilter] = useState("all");
  // const [codingResultSortBy, setCodingResultSortBy] = useState("date");
  // const [codingResultSortOrder, setCodingResultSortOrder] =
  //   useState("assending");
  // const [updateCodingResults, setUpdateCodingResults] = useState(0);
  // const [codingResultsLoading, setCodingResultsLoading] = useState(true);
  // useEffect(() => {
  //   if (admin?.dataSheetId) {
  //     const fetchCodingResults = async () => {
  //       setCodingResultsLoading(true);
  //       const codingResultData = JSON.stringify([
  //         admin?.dataSheetId,
  //         "resultCodingAss",
  //         1,
  //         50,
  //         resultFilter,
  //         resultSortBy,
  //         resultSortOrder,
  //       ]);
  //       const resultResponse = await axios.post(
  //         process.env.REACT_APP_FETCH_RESULTS_API,
  //         codingResultData
  //       );
  //       setCodingResults(resultResponse.data.records);
  //       setAllCodingResults(resultResponse.data.allData);
  //       setTotalCodingResults(resultResponse.data.totalRecords);
  //       setTimeout(() => setCodingResultsLoading(false), 100);
  //     };
  //     fetchCodingResults();
  //   }
  // }, [
  //   updateCodingResults,
  //   codingResultFilter,
  //   codingResultSortBy,
  //   codingResultSortOrder,
  //   admin?.dataSheetId,
  // ]);

  // fetching applications
  // const [applications, setApplications] = useState([]);
  // const [applicationFilter, setApplicationFilter] = useState('');
  // const [applicationSortBy, setApplicationSortBy] = useState('');
  // const [applicationSortOrder, setApplicationSortOrder] = useState('');
  // const [allApplications, setAllApplications] = useState();
  // const [totalApplications, setTotalApplications] = useState();
  // const [updateApplications, setUpdateApplications] = useState(0)
  // const [applicationsLoading, setApplicationsLoading] = useState(true);
  // useEffect(() => {
  //   if(dataSheetId){
  //     const fetchApplications = async () => {
  //       setApplicationsLoading(true)
  //       const applicationsData = JSON.stringify([dataSheetId,1,50,applicationFilter,applicationSortBy,applicationSortOrder])
  //       const applicationResponse =  await axios.post(process.env.REACT_APP_FETCH_APPLICATIONS_API,applicationsData);
  //       setApplications(applicationResponse.data.records);
  //       setAllApplications(applicationResponse.data.allData)
  //       setTotalApplications(applicationResponse.data.totalRecords)
  //       setApplicationsLoading(false)
  //     }
  //     fetchApplications()
  //   }
  // },[updateApplications,applicationFilter,applicationSortBy,applicationSortOrder,dataSheetId])

  // fetch notifications
  // const [notifications, setNotifications] = useState();
  // // const [updateNotifications, setUpdateNotifications] = useState(0);
  // const [notificationsLoading, setNotificationsLoading] = useState(true);
  // useEffect(() => {
  //   // if (admin?.dataSheetId) {
  //   //   const fetchNotifications = async () => {
  //   //     setNotificationsLoading(true);
  //   //     const notificationData = JSON.stringify([
  //   //       admin?.dataSheetId,
  //   //       "notifications",
  //   //     ]);
  //   //     const notificationtResponse = await axios.post(
  //   //       fetchapi,
  //   //       notificationData
  //   //     );
  //   //     setNotifications(notificationtResponse.data.records);
  //   //     setNotificationsLoading(false);
  //   //   };
  //   //   fetchNotifications();
  //   // }
  // }, []);

  // fetch assessment types
  // const [assessmentNames, setAssessmentNames] = useState([]);
  const [updateAssessmentTypes, setUpdateAssessmentTypes] = useState(0);
  // const [assessmentNamesLoading, setAssessmentNamesLoading] = useState(true);
  // useEffect(() => {
  //   if (admin?.questionsSheetId) {
  //     const fetchQuestionSheets = async () => {
  //       setAssessmentNamesLoading(true);
  //       const sheets = await axios.post(
  //         process.env.REACT_APP_FETCH_SHEETS,
  //         admin?.questionsSheetId
  //       );
  //       const data = sheets?.data.flat(1);
  //       const sheetNames = data.filter(
  //         (element) =>
  //           !element.startsWith("inactive_") && !element.startsWith("Sheet")
  //       );
  //       setAssessmentNames(sheetNames);
  //       setAssessmentNamesLoading(false);
  //     };
  //     fetchQuestionSheets();
  //   }
  // }, [admin?.questionsSheetId, updateAssessmentTypes]);

  //fetchQuestions
  // const [assessment, setAssessment] = useState();
  // const [allQuestions, setAllQuestions] = useState();
  // const [updateQuestions, setUpdateQuestions] = useState(0);
  // useEffect(() => {
  //   if (admin?.questionsSheetId) {
  //     const fetchQuestions = async () => {
  //       let api =
  //         "https://script.google.com/macros/s/AKfycbz7SkWSESvthge0tRoRxw9uK6cmhp9gfLvXHZnVL2COVNTAYvQJbHVLf2HNDacCM8ok/exec";
  //       const questionData = JSON.stringify([admin?.questionsSheetId]);
  //       const response = await axios.post(api, questionData);
  //       setAllQuestions(response.data.records);
  //     };
  //     fetchQuestions();
  //   }
  // }, [admin?.questionsSheetId, updateQuestions]);

  return (
    <div className="w-full pb-5">
      <ToastContainer autoClose={3000} />
      {/* Topbar  */}
      <div className="header-bar sticky top-0 left-0 z-20 bg-gray-200/30 lg:bg-transparent backdrop-blur-md lg:backdrop-blur-0 drop-shadow-md lg:drop-shadow-none">
        <Topbar3
          admin={admin}
          page={page}
          sideBarOpen={sideBarOpen}
          setSideBarOpen={setSideBarOpen}
          isprofileMenuOpen={isprofileMenuOpen}
          setIsprofileMenuOpen={setIsprofileMenuOpen}
          isNotificationOpen={isNotificationOpen}
          setIsNotificationOpen={setIsNotificationOpen}
          isSearchOpen={isSearchOpen}
          setSearchOpen={setSearchOpen}
        />
      </div>

      {/* Sidebar  */}
      <div className="absolute top-0 left-0 w-full h-full">
        <div className="h-full w-full relative">
          <motion.div
            className={`absolute z-20 top-0 left-0 w-full h-full bg-[#000]/10 transition-all ${
              sideBarOpen === "open" ? "flex" : "hidden"
            }`}
            onClick={() =>
              setSideBarOpen(
                screen === "large"
                  ? sideBarOpen === "small"
                    ? "open"
                    : "small"
                  : sideBarOpen === "none"
                  ? "open"
                  : "none"
              )
            }
            variants={{
              initial: { opacity: 0 },
              final: { opacity: 1 },
            }}
            initial="small"
            transition={{ duration: 0.3 }}
            animate={sideBarOpen === "open" ? "final" : "initial"}
          />
          <Sidebar
            sideBarOpen={sideBarOpen}
            setSideBarOpen={setSideBarOpen}
            screen={screen}
          />
        </div>
      </div>

      <div onClick={closePopupMenu}>
        <div className="mt-4 flex sm:hidden items-center w-[90vw] mx-auto">
          {page !== "dashboard" &&
            page !== "schedules" &&
            page !== "results" && (
              <div onClick={() => navigate(-1)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-8 h-8 cursor-pointer"
                >
                  <path
                    fillRule="evenodd"
                    d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          <h1 className="heading1">{page}</h1>
        </div>

        {/* Dashboard  */}
        {page === "dashboard" && (
          <Dashboard
            candidates={allSchedules}
            results={allResults}
            loading={resultsLoading || schedulesLoading}
          />
        )}

        {/* applictions Page  */}
        {page === "applications" && (
          <div>
            {/* <Main
              admin={admin}
              tab={tab}
              screen={screen}
              assessmentNames={assessmentNames}
              schedulesLoading={schedulesLoading}
              schedules={schedules}
              scheduleFilter={scheduleFilter}
              setScheduleFilter={setScheduleFilter}
              scheduleSortBy={scheduleSortBy}
              setScheduleSortBy={setScheduleSortBy}
              scheduleSortOrder={scheduleSortOrder}
              setScheduleSortOrder={setScheduleSortOrder}
              totalShedules={totalShedules}
              setUpdateSchedules={setUpdateSchedules}
              resultsLoading={resultsLoading}
              results={results}
              resultFilter={resultFilter}
              setResultFilter={setResultFilter}
              resultSortBy={resultSortBy}
              setResultSortBy={setResultSortBy}
              resultSortOrder={resultSortOrder}
              setResultSortOrder={setResultSortOrder}
              setUpdateResults={setUpdateResults}
              totalResults={totalResults}
              codingResultsLoading={codingResultsLoading}
              codingResults={codingResults}
              codingResultFilter={codingResultFilter}
              setCodingResultFilter={setCodingResultFilter}
              codingResultSortBy={codingResultSortBy}
              setCodingResultSortBy={setCodingResultSortBy}
              codingResultSortOrder={codingResultSortOrder}
              setCodingResultSortOrder={setCodingResultSortOrder}
              setUpdateCodingResults={setUpdateCodingResults}
              totalCodingResults={totalCodingResults}

              // applicationsLoading={applicationsLoading}
              // applications= {applications}
              // totalApplications={totalApplications}
              // applicationFilter={applicationFilter}
              // setApplicationFilter={setApplicationFilter}
              // applicationSortBy={applicationSortBy}
              // setApplicationSortBy={setApplicationSortBy}
              // applicationSortOrder={applicationSortOrder}
              // setApplicationSortOrder={setApplicationSortOrder}
              // setUpdateApplications={setUpdateApplications}
            /> */}
          </div>
        )}

        {/* Candidate Profile Page  */}
        {page === "candidate profile" && (
          <div>
            {/* <CandidateProfile
              loading={
                resultsLoading || schedulesLoading || codingResultsLoading
              }
              admin={admin}
              schedules={allSchedules}
              results={allResults}
              codingResults={allCodingResults}
              // applications={allApplications}
              assessmentNames={assessmentNames}
              setUpdateSchedules={setUpdateSchedules}
              setUpdateResults={setUpdateResults}
              setUpdateCodingResults={setUpdateCodingResults}
            /> */}
          </div>
        )}

        {/* ats pages  */}
        {page === "ATS" && (
          <div>
            <ATSMain />
          </div>
        )}
        {page === "new candidate" && (
          <div>
            <NewCandidate />
          </div>
        )}
        {page === "candidate results" && (
          <div>
            <CandidateProfile />
          </div>
        )}

        {/* jobs pages  */}
        {page === "jobs" && (
          <div>
            <Jobs />
          </div>
        )}
        {page === "new job" && (
          <div>
            <NewJob />
          </div>
        )}
        {page === "edit job" && (
          <div>
            <NewJob />
          </div>
        )}

        {/* questionLibrary pages */}
        {page === "question library" && (
          <div>
            <QuestionLibrary />
          </div>
        )}
        {page === "add question" && (
          <div>
            <LibQuestionUpload />
          </div>
        )}

        {/* assessments pages  */}
        {page === "assessments" && (
          <div>
            <Assessments />
          </div>
        )}
        {page === "new assessment" && (
          <div>
            <NewAssessment />
          </div>
        )}

        {/* question bank Page  */}
        {page === "question bank" && (
          <div>
            {/* <QuestionBank
              tab={assessment}
              setTab={setAssessment}
              assessmentNames={assessmentNames}
              allQuestions={allQuestions}
              setUpdateQuestions={setUpdateQuestions}
              questionSheetId={admin?.questionsSheetId}
              assessmentNamesLoading={assessmentNamesLoading}
            /> */}
          </div>
        )}

        {/* question upload Page  */}
        {page === "question upload" && (
          <div>
            {/* <QuestionUpload
              assessment={assessment}
              assessmentNames={assessmentNames}
              questionSheetId={admin?.questionsSheetId}
              setUpdateQuestions={setUpdateQuestions}
            /> */}
          </div>
        )}

        {/* assessment type Page  */}
        {page === "assessment type" && (
          <div>
            {/* <AssessmentType
              assessmentNames={assessmentNames}
              questionSheetId={admin?.questionsSheetId}
              assessmentNamesLoading={assessmentNamesLoading}
              setUpdateAssessmentTypes={setUpdateAssessmentTypes}
            /> */}
          </div>
        )}

        {/* archive Page  */}
        {/* {page === "archive" && (
          <Archive
            tab={tab}
            candidates={allSchedules}
            schedulesLoading={schedulesLoading}
            scheduleSortBy={scheduleSortBy}
            setScheduleSortBy={setScheduleSortBy}
            scheduleSortOrder={scheduleSortOrder}
            setScheduleSortOrder={setScheduleSortOrder}
            allQuestions={allQuestions}
            assessmentNames={assessmentNames}
          />
        )} */}

        {/* Admin Profile  */}
        {page === "admin profile" && (
          <div>
            <AdminProfile admin={admin} />
          </div>
        )}

        {/* Admin Settings  */}
        {page === "settings" && (
          <div>
            <Settings />
          </div>
        )}

        {/* Notification Page  */}
        {page === "notifications" && (
          <div>
            <Notifications
              notificationsLoading={notificationsLoading}
              notifications={notifications}
              // schedules={allSchedules}
              // schedulesLoading={schedulesLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
