import {
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
} from "firebase/firestore";
import { db } from "../../config/firebase";

export const fetchQuery = (
  adminName,
  tab,
  filterWithJobId,
  sortValue,
  dataSize,
  lastDoc
) => {
  let q = query(
    collection(db, "candidateApplications"),
    where("appliedAtCompany", "==", adminName || "")
  );

  if (filterWithJobId) {
    console.log(filterWithJobId);
    q = query(q, where("appliedJobId", "==", filterWithJobId));
  }

  if (tab) {
    if (tab === "screened") {
      q = query(q, where("currentRound.roundType", "==", "screening"));
    } else if (tab === "assessed") {
      q = query(q, where("currentRound.roundType", "==", "assessment"));
    } else if (tab === "interviewed") {
      q = query(q, where("currentRound.roundType", "==", "interview"));
    } else if (tab === "offered") {
      q = query(q, where("currentRound.roundType", "==", "offered"));
    }
  }

  if (sortValue) {
    q = query(q, orderBy(sortValue.fieldName, sortValue.fieldValue));
  } else {
    q = query(q, orderBy("appliedAt", "desc"));
  }

  if (lastDoc) {
    q = query(q, startAfter(lastDoc));
  }

  if (dataSize) {
    q = query(q, limit(dataSize));
  }

  return q;
};
