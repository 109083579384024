import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { auth, db } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const useFetchJobs = (sort) => {
  const [userAuthState] = useAuthState(auth);
  const [data, setData] = useState([]);
  const fetchData = useCallback(() => {
    let q = query(
      collection(db, "jobsPosted"),
      where("companyName", "==", auth?.currentUser?.displayName),
      where("isDeleted", "==", false)
    );
    if (sort) {
      q = query(q, orderBy(sort?.fieldName, sort?.fieldValue));
    } else {
      q = query(q, orderBy("jobPostedDate", "desc"));
    }

    return onSnapshot(
      q,
      (querySnapshot) => {
        const jobList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(jobList);
      },
      (err) => {
        console.log("Error : Couldn't fetch job - ", err);
        // throw new Error("Error : Couldn't fetch job");
      }
    );
  }, [sort]);

  useEffect(() => {
    const unsubscribe = userAuthState && fetchData();
    return () => unsubscribe?.(); // Cleanup
  }, [fetchData, userAuthState]);

  return data;
};

export default useFetchJobs;
