import React, { useEffect, useState } from "react";
import { Timestamp, doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import Step1 from "./step1";
import Step2 from "./step2";
import JobCreatedModule from "./JobCreatedModule";
import { Button } from "../../../components";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/popup/Popup";
import { db } from "../../../config/firebase";
import { useLocation } from "react-router-dom";
import { auth } from "../../../config/firebase";

const NewJobLayout = () => {
  const uniqueId = new Date().getTime();
  const navigate = useNavigate();
  const [step1Data, setStep1Data] = useState([]);
  const [step2Data, setStep2Data] = useState([{ key: uniqueId }]);
  const [step, setStep] = useState(1);
  const [step1Error, setStep1Error] = useState();
  const [step2Error, setStep2Error] = useState();
  const [showSuccessModule, setShowSuccessModule] = useState(false);
  const [applyLink, setApplyLink] = useState("");
  const [loading, setLoading] = useState(false);

  // get Edit Job Data Details

  const [editJobData, setEditJobData] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editDocIdParams = searchParams.get("editJobId");
  useEffect(() => {
    // console.log(editDocIdParams);
    const getEditJobData = async () => {
      const documentRef = doc(db, "jobsPosted", editDocIdParams);
      const docSnapshot = await getDoc(documentRef);
      if (docSnapshot.exists()) {
        // console.log(docSnapshot.data())
        setEditJobData(docSnapshot.data());
        const data = docSnapshot.data();
        setStep2Data(data.rounds);
      }
    };
    if (editDocIdParams) {
      getEditJobData();
    }
  }, [editDocIdParams]);

  // submit step 1 data

  const submitStep1 = () => {
    setStep1Error("");
    let errorObject = {};
    errorObject.positionTitleError =
      step1Data.positionTitle === "" && "Required";
    errorObject.departmentManagerError =
      step1Data.departmentManager === "" && "Required";
    errorObject.assignedRecruiterError =
      step1Data.assignedRecruiter === "" && "Required";
    errorObject.lastDateToApplyError =
      new Date(step1Data.lastDateToApply).getTime() < Date.now() &&
      "Date can't be of past";
    errorObject.targetDateError =
      new Date(step1Data.targetDate).getTime() < Date.now() &&
      "Date can't be of past";
    errorObject.jobTypeError = step1Data.jobType === "" && "Required";
    errorObject.workExperianceError =
      step1Data.workExperiance === "" && "Required";

    errorObject.numberOfPositionError =
      step1Data.numberOfPosition === "" && "Required";
    // errorObject.jobPrefenceError = step1Data.jobPrefence === "" && "Required";
    errorObject.countryError = step1Data.country === "" && "Required";
    errorObject.stateError =
      step1Data.jobPrefence === "wfo" && step1Data.state === "" && "Required";
    errorObject.cityError =
      step1Data.jobPrefence === "wfo" && step1Data.city === "" && "Required";
    errorObject.jobDescriptionError =
      step1Data.jobDescription === "" && "Required";
    setStep1Error(errorObject);
    if (Object.values(errorObject).some((value) => value !== false)) return;

    setStep((prev) => prev + 1);
  };
  const publishJob = async () => {
    setStep2Error("");
    if (
      step2Data.some(
        (obj) =>
          obj.roundType === undefined ||
          (obj.roundType !== "interview" &&
            obj.selectionCriteria === undefined) ||
          (obj.roundType === "assessment" && obj.assessment === undefined)
      )
    )
      return setStep2Error("Fill all the fields for rounds you have added");

    if (
      step2Data.some(
        (obj) => obj.selectionCriteria < 1 || obj.selectionCriteria > 100
      )
    ) {
      // console.log("criteria problem",step2Data)
      return setStep2Error(
        "Selection Criteria score should be between 1% to 100%"
      );
    }

    const link = `candidateApply?jobId=${step1Data?.jobOpeningId}`;
    setApplyLink(link);

    const data = {
      jobOpeningId: step1Data.jobOpeningId,
      positionTitle: step1Data.positionTitle,
      departmentManager: step1Data.departmentManager,
      assignedRecruiter: step1Data.assignedRecruiter,
      jobType: step1Data.jobType,
      jobPrefence: step1Data.jobPrefence,
      jobDescription: step1Data.jobDescription,
      jobPostedDate: Timestamp.fromDate(new Date()),
      lastDateToApply: Timestamp.fromDate(new Date(step1Data.lastDateToApply)),
      targetDate: Timestamp.fromDate(new Date(step1Data.targetDate)),
      workExperiance: Number(step1Data.workExperiance),
      numberOfPosition: Number(step1Data.numberOfPosition),
      salary: {
        min: Number(step1Data.minimumSalary),
        max: Number(step1Data.maximumSalary),
      },
      address: {
        country: step1Data.country,
        state: step1Data.state,
        city: step1Data.city,
        postalCode: step1Data.postalCode,
      },
      jobStatus: "in-progress",
      applyLink: link,
      companyName: auth?.currentUser?.displayName,
      rounds: step2Data,
      isDeleted: false,
      timeline: [
        {
          name: "job created",
          time: Timestamp.fromDate(new Date()),
        },
        {
          name: "job opened",
          time: Timestamp.fromDate(new Date()),
        },
        {
          name: "last date to apply",
          time: Timestamp.fromDate(new Date(step1Data.lastDateToApply)),
        },
        {
          name: "target date",
          time: Timestamp.fromDate(new Date(step1Data.targetDate)),
        },
      ],
    };
    console.log(data);
    try {
      setLoading(true);
      await setDoc(doc(db, "jobsPosted", step1Data.jobOpeningId), data);
      setShowSuccessModule(true);
    } catch (error) {
      setStep2Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const closeSuccessModule = () => {
    setShowSuccessModule(false);
    navigate("/jobs");
  };

  const updateJob = async () => {
    let updatedTimeline = [
      ...editJobData.timeline,
      {
        name: "job updated",
        time: Timestamp.fromDate(new Date()),
      },
    ];

    if (
      Timestamp.fromDate(new Date(step1Data.lastDateToApply)) !==
      editJobData.lastDateToApply
    ) {
      updatedTimeline = updatedTimeline.filter(
        (obj) => obj.name !== "last date to apply"
      );
      updatedTimeline.push({
        name: "last date to apply",
        time: Timestamp.fromDate(new Date(step1Data.lastDateToApply)),
      });
    }
    if (
      Timestamp.fromDate(new Date(step1Data.targetDate)) !==
      editJobData.lastDateToApply
    ) {
      updatedTimeline = updatedTimeline.filter(
        (obj) => obj.name !== "target date"
      );
      updatedTimeline.push({
        name: "target date",
        time: Timestamp.fromDate(new Date(step1Data.targetDate)),
      });
    }

    const data = {
      positionTitle: step1Data.positionTitle,
      departmentManager: step1Data.departmentManager,
      assignedRecruiter: step1Data.assignedRecruiter,
      jobType: step1Data.jobType,
      jobPrefence: step1Data.jobPrefence,
      jobDescription: step1Data.jobDescription,
      lastDateToApply: Timestamp.fromDate(new Date(step1Data.lastDateToApply)),
      targetDate: Timestamp.fromDate(new Date(step1Data.targetDate)),
      workExperiance: Number(step1Data.workExperiance),
      numberOfPosition: Number(step1Data.numberOfPosition),
      salary: {
        min: Number(step1Data.minimumSalary),
        max: Number(step1Data.maximumSalary),
      },
      updatedAt: Timestamp.fromDate(new Date()),
      address: {
        country: step1Data.country,
        state: step1Data.state,
        city: step1Data.city,
        postalCode: step1Data.postalCode,
      },
      rounds: step2Data,
      timeline: updatedTimeline,
    };
    try {
      setLoading(true);
      const documentRef = doc(db, "jobsPosted", editJobData.jobOpeningId);
      await updateDoc(documentRef, data);
      setShowSuccessModule(true);
    } catch (error) {
      console.log(error);
      setStep2Error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-container">
      <div className="card w-full lg:h-[90vh] mx-auto md:ml-20 mt-2 flex flex-col px-4 lg:px-10 pt-8 overflow-hidden">
        <div className="w-1/3 h-[8%] mx-auto flex flex-col gap-1 items-center">
          <div className="w-[95%] flex justify-between items-center relative">
            <div className="absolute h-1 w-full bg-gray-300">
              <div
                className="absolute top-0 left-0 h-full bg-green-500 transition-all duration-1000"
                style={{ width: `${(step - 1) * 100}%` }}
              />
            </div>
            <div
              className={`
              w-8 h-8 flex items-center justify-center rounded-full transition-all duration-1000 z-10
              ${step > 1 ? "bg-green-500" : "bg-[#8A24F1]"}
            `}
            >
              <span className="text-white font-semibold">
                {step > 1 ? "✓" : "1"}
              </span>
            </div>
            <div
              className={`
              w-8 h-8 flex items-center justify-center rounded-full transition-all duration-1000 z-10
               ${step < 2 ? "bg-gray-400" : "bg-[#8A24F1]"}
            `}
            >
              <span className="text-white font-semibold">2</span>
            </div>
          </div>
          <div className="w-full flex justify-between items-center">
            <span className="text-xs font-medium">Job Details</span>
            <span className="text-xs font-medium">Rounds Details</span>
          </div>
        </div>

        <div className="w-full h-[75%] mt-5">
          {step === 1 && (
            <Step1
              jobDetails={step1Data}
              setJobDetails={setStep1Data}
              jobError={step1Error}
              setJobError={setStep1Error}
              adminName={auth?.currentUser?.displayName}
              editJobData={editJobData}
            />
          )}
          {step === 2 && (
            <Step2
              rounds={step2Data}
              setRounds={setStep2Data}
              editJobData={editJobData}
            />
          )}
        </div>

        <div className="w-full flex flex-col justify-center items-end gap-2">
          <div className="text-xs text-red-500">{step2Error}</div>
          <div className="flex items-center gap-4">
            <Button
              version="secondary"
              text="Cancel"
              width="160px"
              handleClick={() => navigate("/jobs")}
            />
            {step === 2 && (
              <Button
                version="secondary"
                text="Back"
                width="160px"
                handleClick={() => {
                  setStep((prev) => prev - 1);
                  setStep2Error("");
                }}
              />
            )}
            <Button
              handleClick={() =>
                step === 1
                  ? submitStep1()
                  : editJobData
                  ? updateJob()
                  : publishJob()
              }
              loading={loading}
              width="200px"
            >
              {step === 1
                ? "Next"
                : editJobData
                ? "Update"
                : "Save and Publish"}
            </Button>
          </div>
        </div>
      </div>
      {showSuccessModule && (
        <Popup closePopup={closeSuccessModule}>
          <JobCreatedModule
            applyLink={applyLink}
            step1Data={step1Data}
            editJobData={editJobData}
          />
        </Popup>
      )}
    </div>
  );
};

export default NewJobLayout;
