// this email is sent to candiate if the result is pending in  the round
// email contain details for the further instructions for process

const emailTemplate = ({
  candidateName,
  candidateEmail,
  examCode,
  jobTitle,
  companyName,
  startDate,
  expDate,
}) => {
  //content to be changed
  const subject = `Congratulations! You've successfully submitted you assessment.`;
  const body = `
    Hello <b>${candidateName}</b>,<br>

        Congratulations!
        Your assessment for the ${jobTitle} position at ${companyName} have been submitted successfully!  <br><br>

        Your performance on the test will be reviewed and you will be notified if you are selected for the next stage of the hiring process.<br><br>
        
        <b>Please follow our LinkedIn page for Future Job Updates</b> <br>
        LinkedIn - <a href='https://www.linkedin.com/company/talentigo/'>Linkedin-Talentigo</a> <br><br>
        
        Good Luck!<br>
        <b>${companyName}</b>
    `;
  return { subject, body };
};

export default emailTemplate;
